import { PortableText as BasePortableText } from '@portabletext/react'
import React from 'react'
import Figure from './Figure'
import Link from './Link'

interface PortableTextProps {
  blocks: any
}

const PortableText = ({ blocks }: PortableTextProps) => {
  return (
    <div tw='max-w-6xl mx-auto px-4'>
      <BasePortableText
        value={blocks}
        components={{
          block: {
            h1: ({ children }: { children: any }) => <h1 tw='text-3xl'>{children}</h1>,
            h2: ({ children }: { children: any }) => <h2 tw='text-2xl'>{children}</h2>,
            h3: ({ children }: { children: any }) => <h3 tw='text-xl'>{children}</h3>,
          },
          marks: {
            strong: ({ children }: any) => {
              return <strong tw='font-bold text-base inline'>{children}</strong>
            },
            link: ({ children, value }: any) => {
              return <Link {...value} title={children[0]}></Link>
            },
          },
          types: {
            mainImage: Figure,
          },
          list: {
            bullet: ({ children }: { children: any }) => (
              <ul tw='list-disc ml-2 pb-6 list-inside text-base mt-4'>{children}</ul>
            ),
            number: ({ children }: { children: any }) => (
              <ol tw='list-decimal ml-2 pb-6 list-inside text-base mt-4'>{children}</ol>
            ),
          },
        }}
      />
    </div>
  )
}

export default PortableText
