import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { getGatsbyImage } from './../lib/helpers'
import { Image } from './../lib/interfaces/Image'

interface FigureProps {
  value: Image
}

const Figure = (props: FigureProps) => {
  return (
    <div tw='flex justify-start pb-6'>
      <GatsbyImage image={getGatsbyImage(props.value)} alt={props.value.alt} />
    </div>
  )
}

export default Figure
