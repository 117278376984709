import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'
import ImprintSection from '../components/Sections/ImprintSection'

const ImprintPage = () => {
  const portableText: any = useStaticQuery(ImprintQuery).sanityImpressum

  return (
    <Layout>
      <ImprintSection portableText={portableText._rawRichText} />
    </Layout>
  )
}

export default ImprintPage

const ImprintQuery = graphql`
  query ImprintQuery {
    sanityImpressum {
      _rawRichText(resolveReferences: { maxDepth: 10 })
    }
  }
`
