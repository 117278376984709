import React from 'react'
import { styled } from 'twin.macro'
import { LinkType } from '../lib/enums/LinkType'
import { doLinkNavigationByType, getLinkToUseByType } from '../lib/helpers'

interface LinkProps {
  title?: string
  linkType: LinkType
  internalLink: string
  externalLink: string
}

const StyledAnchor = styled.a`
  ::after {
    background-color: #15b5b0;
  }
`

const Link = (props: LinkProps) => {
  const linkToUse: string = getLinkToUseByType(
    props.externalLink,
    props.internalLink,
    props.linkType,
  )

  return (
    <StyledAnchor
      {...props}
      tw='relative after:absolute after:bottom-0 after:left-0 after:h-[2px] after:w-full after:origin-bottom-left after:scale-x-100 hover:after:origin-bottom-right hover:after:scale-x-0 after:transition-transform after:ease-in-out after:duration-300 cursor-pointer'
      onClick={() => doLinkNavigationByType(linkToUse, props.linkType === LinkType.InternalLink)}
    >
      {props.title}
    </StyledAnchor>
  )
}

export default Link
