import React from 'react'
import PortableText from '../PortableText'
import Section from '../Section/Section'
import SectionTitle from '../Section/SectionTitle'

interface ImprintSectionProps {
  portableText: any
}

const ImprintSection = ({ portableText }: ImprintSectionProps) => {
  return (
    <Section backgroundColor='white' maxWidthContainer={false}>
      <div tw='max-w-6xl mx-auto mb-8 px-4'>
        <SectionTitle title='Impressum' color='gray' />
      </div>
      <PortableText blocks={portableText}></PortableText>
    </Section>
  )
}

export default ImprintSection
